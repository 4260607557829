<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i>
                    <!-- <span class="h5"> {{ $t("content.estadisticas")}}</span> -->
                    <span class="h5">Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA DOCUMENTOS STAGE 1</span>
                    <hr />
                    <b-row class="justify-content-center text-center">
                        <b-col cols="4" class="vr my-2 icon-custom">
                            <i class="fas fa-file-powerpoint fa-3x ico"> </i>
                            <br />
                            <span class="lg-numero">{{ listaPlanesAuditoria.length }}</span>
                            <br />
                            <span class="text-muted h6">Planes de auditoria</span>
                        </b-col>
                        <b-col cols="4" class="vr my-2 icon-custom">
                            <i class="fas fa-file-word fa-3x ico"> </i>
                            <br />
                            <span class="lg-numero">{{ listaAsignacionEquipo.length }}</span>
                            <br />
                            <span class="text-muted h6">Asignaciónes del equipo auditor</span>
                        </b-col>
                        <b-col cols="4" class=" my-2 icon-custom">
                            <i class="fas fa-file-alt fa-3x ico"> </i>
                            <br />
                            <span class="lg-numero">{{ listaEstudiosConflicto.length }}</span>
                            <br />
                            <span class="text-muted h6">Estudios de conflicto de interes solicitante</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de documentos stage 1 </span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col md="4" class="my-2">
                            <b-button block size="md" variant="custom" :to="{ name: 'Gestión plan de auditoría stage 1' }">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br />Nuevo plan
                            </b-button>
                        </b-col>
                        <b-col md="4" class="my-2">
                            <b-button block size="md" variant="custom" :to="{ name: 'Gestion asignacion equipo auditor stage 1' }">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br />Nueva asignación
                            </b-button>
                        </b-col>
                        <b-col md="4" class="my-2">
                            <b-button block size="md" variant="custom" :to="{ name: 'Gestion estudios conflictos intereses stage 1' }">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br />Nuevo estudio
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i>
                    <span class="h5"> Documentos stage 1 registrados</span>
                </CCardHeader>

                <CCardBody>
                    <div>
                        <b-row>
                            <b-col md="12">
                                <b-tabs variant="pills">
                                    <b-tab active>
                                        <template slot="title">
                                            <i class="fas fa-file-powerpoint fa-md mr-1"></i> Planes de auditoria
                                        </template>
                                        <b-row class="mt-3">
                                            <b-col lg="2">
                                                <b-form-group label="Registros por página" class="text-muted">
                                                    <b-form-select size="xl" v-model="porPagina[0]" :options="paginaOpciones"></b-form-select>
                                                </b-form-group>
                                            </b-col>
                                            <b-col lg="7"> </b-col>
                                            <b-col lg="3">
                                                <b-form-group label="Busqueda:" class="text-muted">
                                                    <b-input-group size="xl">
                                                        <b-input-group-prepend is-text>
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </b-input-group-prepend>

                                                        <b-form-input type="search" v-model="filter[0]" id="filterInput0" placeholder="Buscar..."></b-form-input>
                                                    </b-input-group>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="12">
                                                <b-table :items="listaPlanesAuditoria" :fields="campoPlanesAuditoria" :current-page="currentPage[0]" :per-page="porPagina[0]" :filter="filter[0]" :filter-included-fields="filterOn[0]" bordered hover show-empty mediun responsive outlined @filtered="onFiltered($event,0)" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                    <template #table-colgroup="campoPlanesAuditoria">
                                                        <col v-for="field in campoPlanesAuditoria.fields" :key="field.key" :style="{width:field.key === 'index'? '5%': field.key === 'fechaCreacion'? '10%': field.key === 'normas'? '45%': field.key === 'opciones'? '10%': '30%',}" />
                                                    </template>
                                                    <template v-slot:cell(normas)="data">
                                                        {{String('* ' +listaNormas.filter((x) => data.value.includes(x.idNorma)).map((y) => ` ${y.descripcion}, `).join(' * '))}}
                                                    </template>
                                                    <template v-slot:cell(fechaCreacion)="data">
                                                        {{ dateFormat(data.value) }}
                                                    </template>
                                                    <template #cell(acciones)="param">
                                                        <b-button :to="{name: 'Gestión plan de auditoría stage 1',params: { id: param.item.idPlanAuditoria },}" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </b-button>
                                                        <b-button @click="eliminarPlanAuditoria(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                            <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                        </b-button>
                                                    </template>
                                                </b-table>
                                            </b-col>
                                            <b-col md="12">
                                                <hr />
                                                <b-pagination v-model="currentPage[0]" :total-rows="totalRows[0]" :per-page="porPagina[0]" align="right" size="sm" class="my-0"></b-pagination>
                                            </b-col>
                                        </b-row>
                                    </b-tab>
                                    <b-tab>
                                        <template slot="title">
                                            <i class="fas fa-file-word fa-md mr-1"></i> Asignaciones del equipo auditor
                                        </template>
                                        <b-row class="mt-3">
                                            <b-col lg="2">
                                                <b-form-group label="Registros por página" class="text-muted">
                                                    <b-form-select size="xl" v-model="porPagina[1]" :options="paginaOpciones"></b-form-select>
                                                </b-form-group>
                                            </b-col>
                                            <b-col lg="7"> </b-col>
                                            <b-col lg="3">
                                                <b-form-group label="Busqueda:" class="text-muted">
                                                    <b-input-group size="xl">
                                                        <b-input-group-prepend is-text>
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </b-input-group-prepend>
                                                        <b-form-input type="search" v-model="filter[1]" id="filterInput1" placeholder="Buscar..."></b-form-input>
                                                    </b-input-group>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="12">
                                                <b-table :items="listaAsignacionEquipo" :fields="campoAsignacionEquipo" :current-page="currentPage[1]" :per-page="porPagina[1]" :filter="filter[1]" :filter-included-fields="filterOn[1]" bordered hover show-empty mediun responsive outlined @filtered="onFiltered($event,1)" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                    <template v-slot:cell(fechaCreacion)="data">
                                                        {{ dateFormat(data.value) }}
                                                    </template>
                                                    <template #cell(acciones)="param">
                                                        <b-button class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'" :to="{name: 'Gestion asignacion equipo auditor stage 1',params: { id: param.item.idAsignacionEquipo },}">
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </b-button>

                                                        <b-button @click="eliminarAsignacionEquipo(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                            <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                        </b-button>
                                                    </template>
                                                </b-table>
                                            </b-col>
                                            <b-col md="12">
                                                <hr />
                                                <b-pagination v-model="currentPage[1]" :total-rows="totalRows[1]" :per-page="porPagina[1]" align="right" size="sm" class="my-0"></b-pagination>
                                            </b-col>
                                        </b-row>
                                    </b-tab>
                                    <b-tab>
                                        <template slot="title">
                                            <i class="fas fa-file-alt fa-md mr-1"></i> Estudios de conflicto de interes
                                            solicitante
                                        </template>
                                        <b-row class="mt-3">
                                            <b-col lg="2">
                                                <b-form-group label="Registros por página" class="text-muted">
                                                    <b-form-select size="xl" v-model="porPagina[2]" :options="paginaOpciones"></b-form-select>
                                                </b-form-group>
                                            </b-col>
                                            <b-col lg="7"> </b-col>
                                            <b-col lg="3">
                                                <b-form-group label="Busqueda:" class="text-muted">
                                                    <b-input-group size="xl">
                                                        <b-input-group-prepend is-text>
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </b-input-group-prepend>
                                                        <b-form-input type="search" v-model="filter[2]" id="filterInput2" placeholder="Buscar..."></b-form-input>
                                                    </b-input-group>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="12">
                                                <b-table :items="listaEstudiosConflicto" :fields="campoEstudiosConflicto" :current-page="currentPage[2]" :per-page="porPagina[2]" :filter="filter[2]" :filter-included-fields="filterOn[2]" bordered hover show-empty mediun responsive outlined @filtered="onFiltered($event,2)" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                    <template v-slot:cell(fechaCreacion)="data">
                                                        {{ dateFormat(data.value) }}
                                                    </template>
                                                    <template #cell(acciones)="param">
                                                        <b-button class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'" :to="{name: 'Gestion estudios conflictos intereses stage 1',params: {id: param.item.idEstudioConflictos,},}">
                                                            <i class="fas fa-search my-0 mx-0"></i>
                                                        </b-button>
                                                        <b-button @click="eliminarEstudiosConflicto(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                            <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                        </b-button>
                                                    </template>
                                                </b-table>
                                            </b-col>
                                            <b-col md="12">
                                                <hr />
                                                <b-pagination v-model="currentPage[2]" :total-rows="totalRows[2]" :per-page="porPagina[2]" align="right" size="sm" class="my-0"></b-pagination>
                                            </b-col>
                                        </b-row>
                                    </b-tab>

                                </b-tabs>
                            </b-col>
                        </b-row>
                    </div>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>
</div>
</template>

<script>
import axios from 'axios';
import CONSTANTES from '@/Constantes.js';

export default {
    data() {
        return {
            datosSession: {
                idCliente: '',
            },

            totalRows: [0, 0, 0],
            currentPage: [1, 1, 1],
            filter: [null, null, null],
            filterOn: [
                [],
                [],
                []
            ],
            porPagina: [10, 10, 10],
            paginaOpciones: [
                10,
                25,
                50,
                100,
                {
                    value: 9999999,
                    text: 'Mostrar todos',
                },
            ],

            campoPlanesAuditoria: [{
                    key: 'index',
                    label: 'N°',
                    class: 'text-center',
                },
                // {
                //     key: 'cliente',
                //     label: 'Empresa',
                //     class: 'text-center',
                // },
                {
                    key: 'normas',
                    label: 'Normas',
                    class: 'text-center',
                },
                {
                    key: 'fechaCreacion',
                    label: 'Fecha de creación',
                    class: 'text-center',
                },
                {
                    key: 'acciones',
                    label: '',
                    class: 'text-center',
                },
            ],
            listaNormas: [{
                    idNorma: 1,
                    descripcion: 'ISO 9001: 2015',
                },
                {
                    idNorma: 2,
                    descripcion: 'ISO 14001: 2015',
                },
                {
                    idNorma: 3,
                    descripcion: 'ISO 45001: 2018',
                },
                {
                    idNorma: 4,
                    descripcion: 'ISO 37001: 2016',
                },
                {
                    idNorma: 5,
                    descripcion: 'ISO 27001: 2013',
                },
                {
                    idNorma: 6,
                    descripcion: 'ISO 20000: 2011',
                },
            ],

            campoEstudiosConflicto: [{
                    key: 'index',
                    label: 'N°',
                    class: 'text-center',
                },
                // {
                //     key: 'razonSocial',
                //     label: 'Empresa',
                //     class: 'text-center',
                // },
                {
                    key: 'nombre',
                    label: 'Nombre',
                    class: 'text-center',
                },
                {
                    key: 'cargo',
                    label: 'Cargo',
                    class: 'text-center',
                },
                {
                    key: 'solicitud',
                    label: 'Solicitud',
                    class: 'text-center',
                },
                {
                    key: 'fechaCreacion',
                    label: 'Fecha de creación',
                    class: 'text-center',
                },
                {
                    key: 'acciones',
                    label: '',
                    class: 'text-center',
                },
            ],

            campoAsignacionEquipo: [{
                    key: 'index',
                    label: 'N°',
                    class: 'text-center',
                },
                // {
                //     key: 'razonSocial',
                //     label: 'Empresa',
                //     class: 'text-center',
                // },
                {
                    key: 'fechaCreacion',
                    label: 'Fecha de creación',
                    class: 'text-center',
                },
                {
                    key: 'acciones',
                    label: '',
                    class: 'text-center',
                },
            ],

            listaPlanesAuditoria: [],
            listaAsignacionEquipo: [],
            listaEstudiosConflicto: [],
        };
    },

    methods: {
        dateFormat(date) {
            return moment(date).format('YYYY-MM-DD')
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems, id) {
            this.totalRows[id] = filteredItems.length;
            this.currentPage[id] = 1;
        },
        listarDocumentosS1() {
            let me = this;
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/documentos-s1', {
                    params: {
                        idCliente: me.datosSession.idCliente,
                        stage: 1,
                    },
                })
                .then(function (response) {
                    me.listaPlanesAuditoria = response.data[0];
                    me.totalRows[0] = me.listaPlanesAuditoria.length;
                    me.listaAsignacionEquipo = response.data[1];
                    me.totalRows[1] = me.listaAsignacionEquipo.length;
                    me.listaEstudiosConflicto = response.data[2];
                    me.totalRows[2] = me.listaEstudiosConflicto.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!' + error);
                });
        },
        listarPlanesAuditoria() {
            let me = this;
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/listar-pack-plan-auditorias-s1', {
                    params: {
                        idCliente: me.datosSession.idCliente,
                    },
                })
                .then(function (response) {
                    me.listaPlanesAuditoria = response.data;
                    me.totalRows[0] = me.listaPlanesAuditoria.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!' + error);
                });
        },
        listarAsignacionEquipo() {
            let me = this;
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/listar-asignacion-equipo', {
                    params: {
                        idCliente: me.datosSession.idCliente,
                        stage: 1,
                    },
                })
                .then(function (response) {
                    me.listaAsignacionEquipo = response.data;
                    me.totalRows[1] = me.listaAsignacionEquipo.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!' + error);
                });
        },
        listarEstudiosConflicto() {
            let me = this;
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/listar-estudios-conflicto', {
                    params: {
                        idCliente: me.datosSession.idCliente,
                        stage: 1,
                    },
                })
                .then(function (response) {
                    me.listaEstudiosConflicto = response.data;
                    me.totalRows[2] = me.listaEstudiosConflicto.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!' + error);
                });
        },

        eliminarPlanAuditoria(param) {
            let me = this;
            me.$swal
                .fire({
                    title: '¿Estas seguro de eliminar el plan de auditoria?',
                    text: '¡No podrás revertir esto!',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Eliminar',
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/eliminar-pack-plan-auditoria-s1', {
                                    idPlanAuditoria: param.item.idPlanAuditoria,
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`,
                                    },
                                }
                            )
                            .then(function (response) {
                                let color = response.data.resultado == 1 ? 'success' : 'error';
                                me.swat(color, response.data.mensaje);
                                me.listarPlanesAuditoria();
                            })
                            .catch(function (error) {
                                me.swat('error', 'Algo salió mal!' + error);
                            });
                    }
                });
        },
        eliminarAsignacionEquipo(param) {
            let me = this;
            me.$swal
                .fire({
                    title: '¿Estas seguro de eliminar la asignación de equipo auditor?',
                    text: '¡No podrás revertir esto!',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Eliminar',
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/eliminar-asignacion-equipo', {
                                    idAsignacionEquipo: param.item.idAsignacionEquipo,
                                    stage: 1,
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`,
                                    },
                                }
                            )
                            .then(function (response) {
                                let color = response.data.resultado == 1 ? 'success' : 'error';
                                me.swat(color, response.data.mensaje);
                                me.listarAsignacionEquipo();
                            })
                            .catch(function (error) {
                                me.swat('error', 'Algo salió mal!' + error);
                            });
                    }
                });
        },
        eliminarEstudiosConflicto(param) {
            let me = this;
            me.$swal
                .fire({
                    title: '¿Estas seguro de eliminar el estudio de conflictos?',
                    text: '¡No podrás revertir esto!',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Eliminar',
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/eliminar-estudio-conflicto', {
                                    idEstudioConflictos: param.item.idEstudioConflictos,
                                    stage: 1,
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`,
                                    },
                                }
                            )
                            .then(function (response) {
                                let color = response.data.resultado == 1 ? 'success' : 'error';
                                me.swat(color, response.data.mensaje);
                                me.listarEstudiosConflicto();
                            })
                            .catch(function (error) {
                                me.swat('error', 'Algo salió mal!' + error);
                            });
                    }
                });
        },
        eliminarReport(param) {
            let me = this;
            me.$swal
                .fire({
                    title: '¿Estas seguro de eliminar el report?',
                    text: '¡No podrás revertir esto!',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Eliminar',
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        axios
                            .post(
                                CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/eliminar-pack-report-s1', {
                                    idReport: param.item.idReport,
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.token}`,
                                    },
                                }
                            )
                            .then(function (response) {
                                let color = response.data.resultado == 1 ? 'success' : 'error';
                                me.swat(color, response.data.mensaje);
                                me.listarReports();
                            })
                            .catch(function (error) {
                                me.swat('error', 'Algo salió mal!' + error);
                            });
                    }
                });
        },
        swat(icon, title) {
            this.$swal
                .mixin({
                    toast: true,
                    showConfirmButton: false,
                    position: 'bottom-right',
                    timer: 3500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer);
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                    },
                })
                .fire({
                    icon: icon,
                    title: title,
                });
        },
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarDocumentosS1();
        }
    },
};
</script>
